export const useDialogStore = defineStore('dialog', () => {
  interface IDialog {
    id: string
    isOpen: boolean
    componentName: string
    zIndex: number
  }

  const dialogs = ref<IDialog[]>([])
  const zIndex = ref(100)

  const openDialog = (componentName: string) => {
    const id = Math.random().toString()
    dialogs.value.push({
      id,
      isOpen: true,
      componentName,
      zIndex: zIndex.value++,
    })
  }

  const closeDialog = (id: string) => {
    const index = dialogs.value.findIndex(d => d.id === id)
    if (index !== -1) {
      dialogs.value[index].isOpen = false
    }
  }

  const deleteDialog = (id: string) => {
    const index = dialogs.value.findIndex(d => d.id === id)
    if (index !== -1) {
      dialogs.value.splice(index, 1)
    }
  }

  const closeAllDialogs = () => {
    dialogs.value = []
  }

  return {
    dialogs,
    openDialog,
    closeDialog,
    deleteDialog,
    closeAllDialogs,
  }
})
